(<template>
  <div class="server-wizard">
    <router-view />
  </div>
</template>)

<script>
  export default {};
</script>

<style scoped>
  .server-wizard {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
  }
</style>
